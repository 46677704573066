import * as React from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import { LayoutProvider, Wrapper } from '../components/layout';
import { cannonStyled } from '../theme';
import { CannonBreadcrumbs } from '../atoms';
import { ResponsiveContainer } from '../atoms/Container';
import { ContactForm } from '../components/sections';
import { useTableOrMobile } from '../hooks';

const query = graphql`
  query {
    strapiContact {
      title
      telephone
      email
      address
      contactDescription
      hoursDays
    }
  }
`;

export interface IContactDetails {
  title: string;
  telephone: string;
  email: string;
  address: string;
  hoursDays: string;
  contactDescription: string;
}

export interface IContactPage {
  strapiContact: IContactDetails;
}

const BreadCrumbsContainer = cannonStyled(
  'div',
  (props: { isMobile: boolean; isTablet: boolean }) => ({
    position: 'absolute',
    top: props.isMobile ? '10px' : '30px',
    left: props.isMobile ? '3px' : props.isTablet ? '0px' : '45px',
    width: '380px',
  })
);

const MainWrapper = cannonStyled(
  Wrapper,
  (props: { isMobile: boolean; isTablet?: boolean }) => ({
    display: 'flex',
    maxWidth: '1280px',
    width: props.isMobile ? '95%' : props.isTablet ? '90%' : '90%',
    justifyContent: 'center',
    padding: props.isMobile ? '50px 10px' : '50px',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflowX: 'hidden',
    textAlign: 'center',
    paddingBottom: '120px',
  })
);

const IndexPage: React.FC<PageProps> = (props: PageProps) => {
  const [isMobile, isTablet] = useTableOrMobile();

  const data = useStaticQuery<IContactPage>(query);

  return (
    <LayoutProvider seo locationSearch={props.location.search}>
      <MainWrapper isMobile={isMobile} isTablet={isTablet} noScroll>
        <BreadCrumbsContainer isMobile={isMobile} isTablet={isTablet}>
          <CannonBreadcrumbs
            pages={[
              { title: 'Home', href: '/' },
              { title: 'Support', href: '' },
              { title: 'Contact Us', href: '/contact-us/' },
            ]}
          />
        </BreadCrumbsContainer>
        <ResponsiveContainer style={{ width: '100%' }}>
          <ContactForm
            title={data.strapiContact.address}
            telephone={data.strapiContact.telephone}
            email={data.strapiContact.email}
            address={data.strapiContact.address}
            hoursDays={data.strapiContact.hoursDays}
            contactDescription={data.strapiContact.contactDescription}
          />
        </ResponsiveContainer>
      </MainWrapper>
    </LayoutProvider>
  );
};

export default IndexPage;
